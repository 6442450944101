import {DeepChat} from "deep-chat-react";
// import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';


const StyledDeepChat = styled(DeepChat)(({ theme }) => ({
    borderRadius: 10,
    width: 'calc(100vw - 40px) !important',
    height: 'calc(100vh - 70px) !important',
    paddingTop: 10,
    left: 20,
    top: 20,
    [theme.breakpoints.down('md')]: {
        padding:'0 !important',
        left:'2px',
        top:'2px',
        width: 'calc(100vw - 6px) !important',
        height: 'calc(100vh - 6px) !important',
    },
}));

const rand = function() {
    return Math.random().toString(10 ).substr(2); // remove `0.`
};


export const Chat = (props)=> {
    const sessionid = props.userId.replace('@', 'x').trim().split('.').join('x') + '-' + rand();
    //const theme = useTheme();
    //alert(theme.breakpoints.down('md'));
    // const useStyles()
    console.debug( "sessionid = " + sessionid);
    return <StyledDeepChat
        // style={{borderRadius: 10, width: '96vw', height: 'calc(100vh - 70px)', paddingTop: 10, left: 20, top: 20}}
        connect={{
            stream: true,
            url:"/chat/completions",
            headers:{
                authorization: "Bearer " + props?.config?.token
            },
            additionalBodyProps:{
                target: props.config?.target || "flowise",
                stream: "true",
                sessionId: sessionid,
            }
    }}
    responseInterceptor={(response)=>{
        let responseText = response?.choices && response?.choices[0]?.delta ? response?.choices[0]?.delta?.content : '';
        let newResponse = {text: responseText};
        return newResponse;
    }}
    ></StyledDeepChat>
}